/**
 * UI Helper Module
 * 
 * Description: Shows and Hides a loading icon
 * Uses: during Ajax requests
 */

export default {
    options: {
        class: {
            ajaxLoadingContainer: '.ajax-loading-container',
        },
    },

    /**
     * Display the loading state
     * 
     */
    loadingActive: function() {
        const self = this;
        const ajaxLoadingElem = document.createElement('div');
        const ajaxLoadingIcon = document.createElement('i');
        
        ajaxLoadingElem.classList.add( self.options.class.ajaxLoadingContainer.replace('.', '') );
        ajaxLoadingIcon.classList.add( 'fas', 'fa-spinner', 'fa-pulse', 'text-primary', 'text-4xl' );
        ajaxLoadingElem.appendChild(ajaxLoadingIcon);
        document.body.appendChild(ajaxLoadingElem);
    },

    /**
     * Hide the loading state
     * 
     */
    loadingInActive: function() {
        const self = this;
        document.querySelector( self.options.class.ajaxLoadingContainer ).remove();
    }
}