import loadingState from "./partials/ui-loading";
import { ww } from './partials/windowWidth';

const filter = {
    options: {
        attributeUrlIgnoreList: ['nonce', 'action', 't_product_cat'],
        class: {
            ajaxLoadingContainer: '.ajax-loading-container',
            defaultOrderbyWrapper: '.emc-woocommerce-ordering .orderby',
            productListWrapper: '.woocommerce-page .post-list-container',
        },
        id: {
            filterForm: 'filter-products',
            resultCount: 'wc-result-count',
            viewbyGrid: 'viewby-grid',
            viewbyList: 'viewby-list'
        },
        accordion: {
            class: {
                active: 'is-active',
                toggle: 'attribute-accordion-toggle',
                contentPanel: 'attribute-accordion-content'
            }
        }
    },

    init: function() {
        const self = this;

        if( document.getElementById(self.options.id.filterForm) == null ) {
            return;
        }

        // Configure the event listeners
        self.initListeners();

        // Configure load more listener
        self.loadMoreListener();

        // Configure accordion panels
        self.initAccordionPanels();

        // Customise the range slider
        self.customiseRangeSlider();

    },

    /**
     * Configure all events and their callbacks for the archive filters
     * 
     */
    initListeners: function() {
        const self = this;
        const filterForm = document.getElementById(self.options.id.filterForm);
        const selectOrderby = document.querySelector(self.options.class.defaultOrderbyWrapper);
        const btnViewByGrid = document.getElementById(self.options.id.viewbyGrid);
        const btnViewByList = document.getElementById(self.options.id.viewbyList);

        // Detect when user has submitted product code search form
        filterForm.addEventListener( 'submit', function(e) {
            e.preventDefault();
            let productCode = filterForm.querySelector('input[type="search"]').value;
                
            if( productCode !== '' ) {
                // Needs to loop over all form data - not hard coded
                self.updateUrlSearchParams( 'm__sku', filterForm.querySelector('input[type="search"]').value );            
            } else {
                self.deleteUrlSearchParam( 'm__sku', '' );
            }           

            self.handleSubmit(self, filterForm)
        });

        // User has pressed enter key on filter form input field
        filterForm.querySelector( 'input[type="search"]' ).addEventListener( 'keyup', function( e ) {
            if( e.key === 'Enter' || e.keyCode === '13' ) {
                let productCode = filterForm.querySelector('input[type="search"]').value;

                if( productCode !== '' ) {
                    // Needs to loop over all form data - not hard coded
                    self.updateUrlSearchParams( 'm__sku', filterForm.querySelector('input[type="search"]').value );            
                } else {
                    self.deleteUrlSearchParam( 'm__sku', '' );
                }
                self.handleSubmit(self, filterForm)
            }
        })

        // User has selected a filter checkbox
        filterForm.querySelectorAll( 'input[type="checkbox"]' ).forEach( function(inputField) {
            inputField.addEventListener( 'change', function(e) {
                e.preventDefault();

                if( e.target.checked ) {
                    // self.addFiltersToUrlSearchParams(new FormData(filterForm) );
                    self.addUrlSearchParam( e.target.name, e.target.value );
                } else {
                    // remove the filter data from the search params
                    self.removeUrlSearchParamValue( e.target.name, e.target.value );
                }

                self.handleSubmit(self, filterForm)
            })
        })

        // Range slider has been upated
        window.addEventListener( 'range-changed', function(e) {
            const data = e.detail;
            
            const fieldMin = document.getElementById(data.sliderId).parentElement.querySelector(`#${data.sliderId.replace('slider-', '')}-min`);
            const fieldMax = document.getElementById(data.sliderId).parentElement.querySelector(`#${data.sliderId.replace('slider-', '')}-max`);
            
            // Update hidden form fields
            fieldMin.value = data.minRangeValue;
            fieldMax.value = data.maxRangeValue;

            // Submit the form
            self.handleSubmit(self, filterForm);
        })

        // Override default orderby functionality with AJAX request
        selectOrderby.addEventListener( 'change', function(e) {
            e.preventDefault();
            
            // Update the orderby hidden field
            filterForm.querySelector('input[name="orderby"]').value = selectOrderby.value;

            // Update the browser address bar
             self.updateUrlSearchParams( 'orderby', selectOrderby.value );            

            // Submit the AJAX request to update the product list
            self.handleSubmit(self, filterForm);
        })

        // Handle the layout change
        btnViewByGrid.addEventListener('click', function(e) {
            e.preventDefault();
            self.handleLayoutChange(e.target);
        })
        btnViewByList.addEventListener('click', function(e) {
            e.preventDefault();
            self.handleLayoutChange(e.target);
        })

    },

    /**
     * Listen for the load more button being clicked
     * 
     */
    loadMoreListener: function() {
        const self = this;
        const loadMoreBtn = document.querySelector('button[data-action="load-more"]');
        const postListContainer   = document.querySelector(self.options.class.productListWrapper);

        if( loadMoreBtn ) {
            loadMoreBtn.addEventListener( 'click', function(e) {
                const data = {
                    action: 'loadmore_products',
                    query: emc_wc_ajax.products,
                    page: emc_wc_ajax.current_page
                }

                jQuery.ajax({
                    url: emc_wc_ajax.ajaxurl,
                    method: 'GET',
                    data: data,
                    beforeSend: function( xhr ) {
                        // let the user know the site is at work
                        loadingState.loadingActive();
                    },
                    success: function(data) {
                        const productsHtml = JSON.parse(data);

                        // Increase the window current_page 
                        emc_wc_ajax.current_page++;

                        // append returned HTML data list container
                        postListContainer.innerHTML += productsHtml;
                        
                        // remove the loading icon
                        loadingState.loadingInActive();

                        self.updateLoadMoreStatus();
                        self.updatePostCountInfo();
                    }
                })
            })
        }
    },

    /**
     * Enable accordion functionality for each attribute filter
     * First accordion is visible on page load (PHP setting)
     * 
     */
    initAccordionPanels: function() {
        const self = this;
        const accordionToggles = document.querySelectorAll(`.${self.options.accordion.class.toggle}`);

        if( ww.getWindowWidth() > 767 ) {
            accordionToggles.item(0).parentElement.classList.toggle(self.options.accordion.class.active);
        }

        // Add click handler to all accordion toggles to toggle the active class
        // Hide and show is handled by CSS
        accordionToggles.forEach( elem => {
            elem.addEventListener('click', e => {
                // Toggle the accordion status
                e.currentTarget.parentElement.classList.toggle(self.options.accordion.class.active);
            })
        })
    },

    /**
     * Submits the specified form data to the AJAX handler
     * 
     * @param {object} self 
     * @param {HTMLNode} form 
     */
    handleSubmit: function(self, form) {
        const postListContainer   = document.querySelector(self.options.class.productListWrapper);

        const data = {
            action: form.querySelector('input[name="action"]').value,
            nonce: form.querySelector('input[name="nonce"]').value,
            query: emc_wc_ajax.products,
            formdata: jQuery(form).serialize()
        }

        jQuery.ajax({
            url: emc_wc_ajax.ajaxurl,
            method: 'GET',
            data: data,
            beforeSend: function ( xhr ) {
                loadingState.loadingActive();
            },
            success: function( data ) {
                const dataObj = JSON.parse(data);

                // replace exisitng content with new products
                postListContainer.innerHTML = dataObj.html;

                // remove loading UI
                loadingState.loadingInActive();

                // As this is a modified query we need to update the ajax object with the new info
                emc_wc_ajax.current_page = 1;
                emc_wc_ajax.found_posts = dataObj.found_posts;
                emc_wc_ajax.max_num_pages = dataObj.max_num_pages;
                emc_wc_ajax.products = dataObj.products;

                self.updateLoadMoreStatus();
                self.updatePostCountInfo();
            } 
        })
    },

    /**
     * Update the UI with the new query stats
     * 
     */
    updatePostCountInfo: function() {
        const self = this;
        const postCount = document.getElementById('lm-post-count');
        const foundPosts = document.getElementById('lm-found-posts');

        if( emc_wc_ajax.current_page == emc_wc_ajax.max_num_pages || emc_wc_ajax.found_posts === 0 ) {
            postCount.innerHTML = emc_wc_ajax.found_posts;
        } else {
            postCount.innerHTML = emc_wc_ajax.current_page * emc_wc_ajax.posts_per_page;
        }       

        foundPosts.innerHTML = emc_wc_ajax.found_posts;

        document.getElementById(self.options.id.resultCount).innerHTML = emc_wc_ajax.found_posts + ' ';
    },

    /**
     * Sets the visibility of the load more button depending on values query data
     * 
     */
    updateLoadMoreStatus: function() {
        const self = this;
        const loadMoreBtn = document.querySelector('button[data-action="load-more"]');

        if( loadMoreBtn === null )
            return;
        
        if( emc_wc_ajax.current_page >= emc_wc_ajax.max_num_pages ) {
            loadMoreBtn.classList.add('hidden');
        }
        else {
            loadMoreBtn.classList.remove('hidden');
        }
    },

    /**
     * User has requested to either view by grid or by list
     * 
     * @param {HTMLNode} button 
     */
    handleLayoutChange: function(button) {
        const self = this;
        const btnParent = button.parentElement;
        const btnSibling = btnParent.querySelector('button:not(#' + button.id + ')');
        const postListContainer   = document.querySelector(self.options.class.productListWrapper);

        // Update button state and the sibling button state
        button.disabled = true;
        btnSibling.disabled = false;

        // Update browser URL query search param
        self.updateUrlSearchParams( 'viewby', button.value );

        // Update the layout of the post list container
        // Default layout is list and this requires no class
        postListContainer.classList.toggle( 'viewby-grid' );
    },

    /**
     * Adds all form data to the url search params as a key value pair
     * Optional ignore list for specific attribute names (see options object at top)
     * 
     * @param {FormData} formData 
     */
    addFiltersToUrlSearchParams: function(formData) {
        const self = this;

        for( const pair of formData.entries() ) {
            if( self.options.attributeUrlIgnoreList !== null ) {
                if( ! self.options.attributeUrlIgnoreList.includes( pair[0] ) ) {
                    this.updateUrlSearchParams( pair[0], pair[1] );
                }
            }
        }
    },

    /**
     * Adds the provided name and value parameters to the URL query params
     * History state is also updated
     * 
     * @param {string} name 
     * @param {mixed} value 
     */
    updateUrlSearchParams: function(name, value) {
        var searchParams = new URLSearchParams(window.location.search);
        searchParams.set(name, value);  

        var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        history.pushState(null, '', newRelativePathQuery);
    },
    
    addUrlSearchParam: function(name, value) {
        var searchParams = new URLSearchParams(window.location.search);
        searchParams.append(name, value);

        var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        history.pushState(null, '', newRelativePathQuery);
    },

    /**
     * Remove a single query param that matches the value param
     * URLSearchParams.delete() removes all instances
     * 
     * @param {string} name 
     * @param {string} value 
     */
    removeUrlSearchParamValue(name, value = null) {
        var searchParams = new URLSearchParams(window.location.search);
        let unmatchedParams = [];

        // Store any query params matching this name but with a different value
        // These are added back further down
        for( var pair of searchParams.entries() ) {
            if( pair[0] === name && pair[1] !== value ) {
                unmatchedParams.push([pair[0], pair[1]])
            }
        }
        
        // Removes all query params matching name param
        searchParams.delete(name);

        // Add the unmatched param name values back to the query string
        if( unmatchedParams.length > 0 ) {
            unmatchedParams.forEach(element => {
                searchParams.set( element[0], element[1] );
            });
        }
        
        // Update the window location
        var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        history.pushState(null, '', newRelativePathQuery);
    },

    /**
     * Removes all occurences of a query poaram
     * 
     * @param {string} name 
     * @param {string} value 
     */
     deleteUrlSearchParam(name) {
        var searchParams = new URLSearchParams(window.location.search);

        // Removes all query params matching name param
        searchParams.delete(name);

        // Update the window location
        var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        history.pushState(null, '', newRelativePathQuery);
    },

    customiseRangeSlider: function() {
        const self = this;
        const rangeSliders = document.getElementsByTagName('range-selector');

        window.onload = function(e) {
            for( let rangeSlider of rangeSliders ) {
                const legendAppend = rangeSlider.getAttribute('legend-append') || '';
    
                if( legendAppend ) {
                    const legends = rangeSlider.shadowRoot.querySelectorAll('.legend div');
                    const rangeLabels = rangeSlider.shadowRoot.querySelectorAll('.range-span-label');
                    // Add legend to end of slider key
                    legends.forEach( legend => {
                        legend.innerHTML = legend.innerHTML + legendAppend
                    })
                    // Add legend to end of the slider labels
                    rangeLabels.forEach( rangeLabel => {
                        let legendElem = document.createElement('span');
                        legendElem.innerHTML = legendAppend;
                        rangeLabel.after(legendElem);
                        rangeLabel.style.fontWeight = "400"
                    })
                }
            }
        }
        
    }

}


filter.init();